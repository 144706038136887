<template>
  <v-card>
    <v-card-title class="text-h6" v-html="title"></v-card-title>
    <v-card-text v-html="description"></v-card-text>

    <v-card-text>
      <v-text-field
        v-model="confirm_code"
        class="c-input-small"
        @keyup.enter="confirm"
        :label="label"
        :placeholder="placeholder"
        autofocus
        dense
        single-line
        outlined
        clearable
        hide-details
        append-icon="mdi-qrcode-scan"
        @click:append="showQRCodeScan('confirm_code')"
      ></v-text-field>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel" v-if="showCancelButton">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn color="green darken-1" text @click="confirm">
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="qrScanDialog" max-width="100vw">
      <QRCodeScanner
        v-if="qrScanDialog"
        :name="qrScanType"
        :auto-close="false"
        @close="hideQRCodeScan"
        @onScanned="onQRScanned"
      />
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: "ConfirmBox",
  components: {
    QRCodeScanner: () => import("@/components/common/QRCodeScanner"),
  },
  props: {
    title: {
      type: String,
      default: () => "Xác nhận",
    },
    description: {
      type: String,
      default: () => "Bạn có chắc chắn?",
    },
    label: {
      type: String,
      default: () => "Mã xác nhận",
    },
    placeholder: {
      type: String,
      default: () => "Nhập lại mã để xác nhận",
    },
    codes: {
      type: Array,
      default: () => [],
    },
    showCancelButton: {
      type: Boolean,
      default: () => true,
    },
  },
  data: () => ({
    confirm_code: null,
    qrScanDialog: false,
    qrScanType: null,
  }),
  methods: {
    showQRCodeScan(type) {
      this.qrScanDialog = true;
      this.qrScanType = type;
    },
    hideQRCodeScan() {
      this.qrScanDialog = false;
      this.qrScanType = null;
    },
    onQRScanned(filter) {
      this[filter.name] = filter.value;
      this.confirm();
    },
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      if (!this.confirm_code) {
        this.confirm_code = null;
        this.$vToastify.error(
          this.$t("messages.no_input_with_label", { label: this.label })
        );
        document.getElementById("error_sound_player").play();
        return false;
      }
      if (!this.codes.includes(this.confirm_code)) {
        this.confirm_code = null;
        this.$vToastify.error(
          this.$t("messages.wrong_with_label", { label: this.label })
        );
        document.getElementById("error_sound_player").play();
        return false;
      }
      this.$emit("confirm", this.confirm_code);
    },
  },
};
</script>

<style scoped></style>
